
  export const style={
    inpuStyle:{
      colorInput:"#222222"
    },
    url:{
      urlClient:"https://pelando.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/pelando_secondary_logo.png",
      secondaryLogonLogo: "null",
    },
    variables:{
       customMenuItem: null,
    },
    CurrencyName:{
      name:"Foguinho/Foguinhos"
    },
    teamName:{
      name:"pelando"
    },
    hasToken: false,
    hostHeader: "pelando"
  }
